import React from 'react';

const Billing = () => {
    return (
        <div style={{ minHeight: '95vh', margin: '3em', textAlign: 'center' }}>
            <h2>This feature has been removed for the archived version.</h2>
        </div>
    );
};

export default Billing;
