import AccordionComponent from "../components/Pages/FAQ/AccordionComponent.js"
import Footer from "../layouts/Footer"

const FAQ = () => {
    return(
        <div style={{height:'80vh'}}>
            <AccordionComponent />
            <Footer />
        </div>
  )
}

export default FAQ